import { getEvenRowTableBackground, getOddRowTableBackground, stimConfigSummary } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { localizeEpochJustTime } from '../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

type StimwaveFormSummary = {
    stim_waveform_index: number;
    timestamp: number;
    stim_config: string;
};

interface StimWaveformTableProps {
    session: number;               // Session ID
    summary: StimwaveFormSummary[];
}

export default function StimWaveformTable(props:StimWaveformTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}
    >
        <Table
            aria-labelledby="tableTitle"
            sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
            }}
        >
            <thead>
                <tr>
                    <th style={{ width: 10, padding: '12px 0px', textAlign: 'center' }}>#</th>
                    <th style={{ width: 25, padding: '12px 6px' }}>Timestamp</th>
                    <th style={{ width: 65, padding: '12px 6px' }}>Stim Config</th>
                </tr>
            </thead>
            <tbody>
            {props.summary.map((row:any, index:number) => (
                    <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td align="center">
                            <Typography level="body-sm">{row.stim_waveform_index + 1 }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm"><Link to={`/stimwaveform?wavenumber=${row.stim_waveform_index + 1}&wavecount=${props.summary.length}&session=${props.session}`} component={RouterLink}>{ localizeEpochJustTime(row.timestamp) }</Link></Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{stimConfigSummary(JSON.parse(row.stim_config))}</Typography>
                        </td>
                    </tr>
                    ))}
            
            </tbody>
        </Table>
    </Sheet>
);
}