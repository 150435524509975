import { pickColorByMode } from '../utils';
import { useMemo, useRef } from 'react';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, PointElement, Title, LineElement, BarElement, BarController, LineController } from "chart.js";
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { useColorScheme } from '@mui/joy/styles';
import { PlusOne } from '@mui/icons-material';

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, PointElement, Title, LineElement, BarController, LineController, BarElement );

export interface StimWaveformChartProps {
    sessionID: number;
    waveNumber: number;
    waveform: number[];
    pulseWidth: number;
}

export default function StimWaveformChart(props:StimWaveformChartProps) {
    const { mode } = useColorScheme();

    const getTitle = ():string => {
        return `Session ${props.sessionID} / Waveform #${props.waveNumber}`;
    }

    console.log(`starting up a stimwaveformchart, length of waveform: ${props.waveform.length}`);
    console.log(`first few samples: ${props.waveform[0]} ${props.waveform[1]} ${props.waveform[2]} ${props.waveform[3]}`);

    const unit = "mA";
    const maxValue = Math.max(...props.waveform);

    const options:any = {
        scales: {
            y: {
                min: 0,
                max: maxValue,
                ticks: {
                    color: pickColorByMode(mode!, "#000000", "#ffffff"),
                    callback: function(value:string, index:number, ticks:any) {
                        return `${value} ${unit}`;
                    }
                },
                grid: {
                    color: pickColorByMode(mode!, "#E0E0E0", "#202020"),
                }
            },
            x: {
                ticks: {
                    color: pickColorByMode(mode!, "#000000", "#ffffff"),
                },
                grid: {
                    color: pickColorByMode(mode!, "#E0E0E0", "#202020"),
                }
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: `${getTitle()}`,
                color: pickColorByMode(mode!, "#000000", "#ffffff"),
                font: {
                    size: 24,
                    family: "Inter"
                }
            },
            tooltip: {
                callbacks: {
                    title: (item:any): string => {
                        return `Sample #${item[0].dataIndex}`;
                    }
                }
            },
            legend: {
                position: 'top',
                labels: {
                    color: pickColorByMode(mode!, "#000000", "#ffffff"),
                },
                title: {
                    color: 'red'
                },
                onClick: (e:any) => {
                    console.warn(`plugins.legend.onClick invoked!`);
                    e.native.stopPropagation()
                }
            },
        },
        title: {
            display: true,
            text: 'detail'
        }
    }

    const labels:string[] = [];

    for (let i = 0; i < props.waveform.length; i++) {
        labels.push('');
        //const usPerSample = props.pulseWidth / 500.0;
        //const thisOne = Math.floor(i * usPerSample);
        //labels.push(`${thisOne}μs`);
    }

    const data: any = {
        labels: labels,
        datasets: [{
            label: `Current`,
            backgroundColor: pickColorByMode(mode!, "#0000ff", "#40a0ff"),
            data: props.waveform,
            borderColor: pickColorByMode(mode!, "#0000ff", "#40a0ff"),
            hoverBackgroundColor: pickColorByMode(mode!, "#00ff00", "#00ff00"),
            type: "line",
            pointRadius: 1,
            pointHoverRadius: 8,
            order: 0,
            lineWidth: 1,
            borderWidth: 1,
            unit: "mA"
        }
        ]
    };

    const chartRef = useRef<ChartJS<"line", number[], string>>(null);

    return (
        <Line
            ref={chartRef}
            data={data}
            options={options}
        />
    );
}
