import Typography from '@mui/joy/Typography';
import { useMemo } from 'react';
import { stimConfigSummary } from '../utils';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { NetworkHelper } from '../util/NetworkHelper';
import StimWaveformChart, { StimWaveformChartProps } from '../components/StimWaveformChart';
import { useEffect } from "react";

function waveFormHexToNumbers(waveformHex: string): number[] {
    console.log(`waveformHex = ${waveformHex}`);
    const waveformNumbers = waveformHex.match(/.{1,2}/g)?.map(hex => Math.floor((parseInt(hex, 16) << 5)) / 1000) || [];
    return waveformNumbers;
}

export default function StimWaveform() {
    useEffect(() => {
        document.title = `Arcadia - Stim Waveform Session ${session}, #${waveIndex + 1}`;
    }, []);

    const [searchParams] = useSearchParams();
    const session = parseInt(searchParams.get("session") || '');
    const waveIndex = parseInt(searchParams.get("wavenumber") || '') - 1;

    // TODO: figure out a cleaner way to fix the data type for parseInt - coalesce in useparams somehow?

    const stimWaveformQuery = useQuery({
        queryKey: ['getStimWaveform', session, waveIndex],
        queryFn: async () => {
            const data = await NetworkHelper.getInstance().doGetStimWaveform(session, waveIndex)
            console.log(`query done: data = ${JSON.stringify(data)}`);

            return data
        }
    });

    if (!stimWaveformQuery.isSuccess) {
        return <p>Loading...</p>;
    }

    const stimConfig = JSON.parse(stimWaveformQuery.data.stim_config);

    const chartProps:StimWaveformChartProps = {
        waveNumber: waveIndex + 1,
        waveform: waveFormHexToNumbers(stimWaveformQuery.data.stim_waveform),
        pulseWidth: stimConfig.stimCfg.electrodes[0].pulseWidth * 10,   // * 10 is to convert it to μs
        sessionID: session
    };

    return (
    <>
        <Typography level="h2" component="h1">Stim Waveform</Typography>
        <span>
            Config: <b>{ stimConfigSummary(stimConfig) }</b>
        </span>
        <StimWaveformChart {...chartProps} />
    </>
    );
}
