import { useParams } from 'react-router-dom';
import { NetworkHelper } from '../util/NetworkHelper';
import { useState, useEffect, useMemo } from 'react';
import Typography from '@mui/joy/Typography';
import PassiveLogTable from '../components/PassiveLogTable';
import MetadataTable from '../components/MetadataTable';
import StimWaveformTable from '../components/StimWaveformTable';
import StatsTable from '../components/StatsTable';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';

interface SummaryField {
    name: string;
    value: string;
}

export default function SessionDetail() {
    const populateSummaryFields = () => {
        const fields: SummaryField[] = [];
        if (sessionSummary) {
            fields.push({ name: 'Session ID', value: sessionSummary.session.id.toString() });
            fields.push({ name: 'Source', value: sessionSummary.session.source });
            fields.push({ name: 'Duration', value: sessionSummary.session.duration.toString() + "s" });
            fields.push({ name: 'Packet count', value: sessionSummary.session.packet_count.toString() });
            fields.push({ name: 'First packet', value: localizeDate(sessionSummary.session.first_packet_date) });
            fields.push({ name: 'Inserted', value: localizeDate(sessionSummary.session.insert_date) });
        }
        return fields;
    };

    const { id } = useParams();
    const net = NetworkHelper.getInstance();
    const [sessionSummary, setSessionSummary] = useState<any>(null);
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);
    const summaryFields = useMemo(populateSummaryFields, [sessionSummary]);

    useEffect(() => {
        document.title = `Arcadia - Session Detail #${id}`;
    }, [id]);

    useEffect(() => {
        let cancelMe = false;

        const doAsyncWork = async () => {
            const result = await net.doGetSessionSummary(parseInt(id!));

            if (!cancelMe) {
                if (!result) {
                    console.log("Session summary never came back, that's unfortunate");
                }

                setSessionSummary(result);
            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            cancelMe = true;
        }
    }, [id, net]);

    if (sessionSummary) {
        return (
        <>

            <Typography level="h2" component="h1">Session Detail</Typography>
            <br />
            <table>
                <tbody>
                <tr>
                    <td style={{ width: "47%", verticalAlign: "top" }}>
                    <Typography level="h4" component="h1">Summary</Typography>
                    <Sheet
                        className="OrderTableContainer"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            borderRadius: '1px',
                            borderWidth: '1px',
                            minHeight: 0,
                            flexShrink: 0
                        }}
                    >
                        <Table
                            aria-labelledby="tableTitle"
                            sx={{
                                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '4px',
                                '--TableCell-paddingX': '8px',
                            }}
                        >
                            <tbody>
                            {summaryFields.map((row:any, index:number) => (
                            <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                                <td>
                                    <Typography level="body-sm">{row.name}</Typography>
                                </td>
                                <td>
                                <Typography level="body-sm">{row.value}</Typography>
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Sheet>
                    <br />
                    <Typography level="h4" component="h2">Stats</Typography>
                    <StatsTable stats={sessionSummary.derived.stats} session={sessionSummary.session.id} />

                    </td>
                    <td style={{ width: "6%" }}></td>
                    <td style={{ width: "47%", verticalAlign: "top" }}>
                        <Typography level="h4" component="h2">Metadata</Typography>
                        <MetadataTable metadata={sessionSummary.metadata} />
                        <br />
                        <Typography level="h4" component="h2">Stim Waveform</Typography>
                        <StimWaveformTable summary={sessionSummary.stim_waveform} session={sessionSummary.session.id} />
                    </td>
                </tr>
                </tbody>
            </table>
            <Typography level="h4" component="h2">Passive Log</Typography>
            <PassiveLogTable log_entries={sessionSummary.derived.log_entries} />
        </>
        );
    }
    else {
        return <p>Fetching session summary data...</p>
    }
}
